.slider {
  position: relative;
  width: 100%;
  margin-bottom: 0.75rem;
  top: -2px;
  height: 5px;
  overflow: hidden;
}

.slider__track,
.slider__range {
  position: absolute;
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: #000;
  z-index: 2;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: relative;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #000;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #000;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* CAROUSEL COMPONENT */

/** @deprecated */
.slides {
  display: flex;
}

/** @deprecated */
.slides input {
  display: none;
}

/** @deprecated */
.slide {
  width: 25%;
  position: relative;
  transition: 1s;
}

.manual-carousel-slides {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
}

.manual-btn {
  background-color: #adb5bd;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
}

.manual-btn:not(:last-child) {
  margin-right: 10px;
}

.manual-btn:hover {
  background-color: #ef0083;
}

.carousel-item {
  animation: fade 1s ease-in;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
